<template>
  <div>
    <div class="bg-white" style="min-height: 87vh;">
      <div class="padding stu-info"><border-title titleName="班级信息" /></div>

      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="选择课程" prop="curriculum_name" style="width: 50%;">
          <el-button type="primary" size="small" @click="chooseCoursesSingle" v-if="ruleForm.curriculum_name ==''">选择课程</el-button>
          <div v-if="ruleForm.curriculum_name !=''" class="flex flex-wrap-wrap">
            <div class="padding-lr-sm" style="background-color: #dbebfe;">{{ ruleForm.curriculum_name }}</div>
            <el-button type="primary" size="small" @click="chooseCoursesSingle" style="margin-left: 10px;">重新选课</el-button>
          </div>
        </el-form-item>
        
        <el-form-item label="班级名称" prop="class_name" style="width: 50%;"><el-input v-model="ruleForm.class_name" placeholder="请输入班级名称"></el-input></el-form-item>
        <el-form-item label="满班人数" prop="class_num">
          <el-input-number v-model="ruleForm.class_num" controls-position="right" :min="1" style="width: 100px;"></el-input-number>
        </el-form-item>
        <el-form-item label="每次上课" required>
          <div class="padding-sm" style="background-color: #f1eeff;width: 250px;">
            <div>
              <span class="margin-right">学生扣除</span>
              <el-input-number v-model="ruleForm.student_time" controls-position="right" :min="1" style="width: 100px;"></el-input-number>
              <span class="margin-left">课时</span>
            </div>
            <div class="margin-top-xs">
              <span class="margin-right">教师获得</span>
              <el-input-number v-model="ruleForm.teacher_time" controls-position="right" :min="1" style="width: 100px;"></el-input-number>
              <span class="margin-left">课时</span>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="招生状态" prop="recruit_status">
          <el-select v-model="ruleForm.recruit_status" style="width: 46%;"><el-option v-for="status in recruitStatusList" :label="status.name" :value="status.value" /></el-select>
          <el-tooltip class="item margin-left-sm" effect="dark">
            <i class="el-icon-question text-blue" />
            <div slot="content">
              开放：满班后继续招生，学员可以报读班级
              <br />
              满班后停止：满班后停止招生，学员不可以报读本班
              <br />
              停止：直接停止招生，学员不可以报读本班
            </div>
          </el-tooltip>
        </el-form-item>
        <el-form-item label="开班日期">
          <el-col :span="11">
            <el-form-item prop="starttime">
              <el-date-picker
                :picker-options="pickerOptions0"
                type="date"
                placeholder="选择日期"
                v-model="ruleForm.starttime"
                value-format="yyyy-MM-dd"
                style="width: 100%;"
              ></el-date-picker>
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item label="结班日期">
          <el-col :span="11">
            <el-form-item prop="starttime">
              <el-date-picker
                :picker-options="pickerOptions1"
                type="date"
                placeholder="选择日期"
                v-model="ruleForm.endtime"
                value-format="yyyy-MM-dd"
                style="width: 100%;"
              ></el-date-picker>
            </el-form-item>
          </el-col>
        </el-form-item>

        <div class="padding stu-info margin-top-xl"><border-title titleName="其他信息" /></div>

        <el-form-item label="默认教室" prop="roomid">
          <el-select v-model="ruleForm.roomid" :disabled="disablesd">
            <el-option v-for="room in classroomList" :key="room.id" :label="room.name" :value="room.id"></el-option>
          </el-select>
          <el-button style="margin-left: 20px;" type="primary" size="small" :disabled="disablesd" @click="dialogFormVisible = true">新建教室</el-button>
        </el-form-item>
        <el-form-item label="班主任">
          <div class="flex aligin-items-center margin-top-xs" v-for="(item, index) in ruleForm.tlist" :key="index">
            <el-select v-model="item.type" style="width: 110px;" :disabled="disablesd">
              <el-option label="教师" :value="1"></el-option>
              <el-option label="助教" :value="2"></el-option>
            </el-select>
            <div class="margin-left-sm">
              <AsAutocomplete
                v-model="item.teacherName"
                :fetch-suggestions="queryTeacherSearch"
                keyName="full_name"
                @selectChange="(item)=>{selectChange(index,item)}"
                placeholder="输入老师姓名、手机号搜索"
              >
                <div slot="title" >
                  <div class="padding-lr flex justify-content-between">
                    <div>姓名</div>
                    <div class="">手机号</div>
                  </div>
                </div>
                <template slot="item" slot-scope="row">
                  <div style="display: flex" class=" hover-blue cursor flex justify-content-between aligin-items-center">
                    
                    <div>
                      <i v-if="row.item.sex == 1" class="el-icon-male text-blue"></i>
                      <i v-if="row.item.sex == 2" class="el-icon-female text-red"></i>
                      {{ row.item.full_name }}
                    </div>
                    <div class="" style="font-size: 12px">{{ row.item.phone }}</div>
                  </div>
                </template>
              </AsAutocomplete>
            </div>
            <i v-if="index < 1" class="el-icon-circle-plus line-blue margin-left cursor" @click="addTeacher" />
            <i v-if="index > 0" class="el-icon-circle-close line-blue margin-left cursor" @click="delTeacher(index)" />
          </div>

          <div class="margin-left-sm">
            <i class="iconfont icon-yuandianxiao line-blue"></i>
            以上教师和助教可以对班级学员记上课和不知作业（需要拥有相关权限）
          </div>
        </el-form-item>

        <el-form-item label="备注" prop="remarks">
          <el-input type="textarea" v-model="ruleForm.remarks" placeholder="最多可以输入150字" max="150" style="width: 46%;"></el-input>
        </el-form-item>
      </el-form>

      <div class=" margin-left-xl triangle-topleft"><el-button @click="submitForm">确定</el-button></div>
    </div>

    <!-- 新建教室弹窗 -->
    <el-dialog title="新建教室" :visible.sync="dialogFormVisible">
      <el-form :model="classroomForm" :rules="classroomFormRules" ref="classroomForm">
        <el-form-item label="教室名称" label-width="100px" prop="name"><el-input v-model="classroomForm.name" autocomplete="off" /></el-form-item>
        <el-form-item label="校区" label-width="100px" prop="school_id">
          <el-select v-model="classroomForm.school_id" placeholder="请 选择校区" @change="schoolChange">
            <el-option v-for="school in schoolList" :key="school.id" :label="school.name" :value="school.id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="addClassroom">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import BorderTitle from '@/components/border-title/index.vue';
import AsAutocomplete from '@/components/as-autocomplete/index.vue';
// import { formatTime } from '@/utils/util.js';
export default {
  components: {
    BorderTitle,
    AsAutocomplete
  },
  data() {
    return {
      teacherName: '',
      recruitStatusList: [
        {
          name: '开放',
          value: 0
        },
        {
          name: '满班后停止',
          value: 1
        },
        {
          name: '停止',
          value: 2
        }
      ],
      ruleForm: {
        curriculum_name: '',
        curriculumid: 0,
        class_name: '',
        class_num: '1',
        recruit_status: '',
        starttime: '',
        endtime: '',
        student_time: '1',
        teacher_time: '1',
        roomid: 0, // 教室id
        teachName: '1',
        tlist: [
          {
            type: 1,
            teacherName: ''
          }
        ], // 班主任id
        remarks: ''
      },
      rules: {
        curriculum_name: [
          {
            required: true,
            message: '请选择课程',
            trigger: 'change'
          }
        ],
        class_name: [
          {
            required: true,
            message: '请输入班级名称',
            trigger: 'blur'
          }
        ],
        class_num: [
          {
            required: true,
            message: '请输入满班人数',
            trigger: 'blur'
          }
        ],
        student_time: [
          {
            required: true,
            message: '请输入课时',
            trigger: 'blur'
          },
          {
            min: 1,
            max: 20,
            message: '最少1个课时',
            trigger: 'blur'
          }
        ],
        teacher_time: [
          {
            required: true,
            message: '请输入课时',
            trigger: 'blur'
          },
          {
            min: 1,
            max: 20,
            message: '最少1个课时',
            trigger: 'blur'
          }
        ]
        // starttime: [
        //   {
        //     type: 'date',
        //     required: true,
        //     message: '请选择日期',
        //     trigger: 'change'
        //   }
        // ],
        // endtime: [
        //   {
        //     type: 'date',
        //     required: true,
        //     message: '请选择时间',
        //     trigger: 'change'
        //   }
        // ],
      },
      dialogFormVisible: false,
      classroomList: [],
      classroomForm: {
        name: '',
        remark: '',
        school_id: ''
      },
      classroomFormRules: {
        name: [
          {
            required: true,
            message: '请输入教室名称',
            trigger: 'blur'
          },
          {
            min: 1,
            max: 50,
            message: '长度在 1 到 50 个字符',
            trigger: 'blur'
          }
        ],
        school_id: [
          {
            required: true,
            message: '请选择校区',
            trigger: 'change'
          }
        ]
      },
      schoolList: [],
      disablesd: true,
      pickerOptions0: {},
      pickerOptions1: {
        disabledDate: time => {
          if (this.endtime) {
            return time.getTime() < this.starttime;
          }
          return time.getTime() < Date.now() - 8.64e7;
        }
      }
    };
  },
  created() {
    this.getClassroomList();
    this.getTeacherList();
    this.getSchoolList();
  },
  mounted() {
    if(this.$route.params.id) this.getClassDetail(this.$route.params.id)
  },
  computed: {},
  methods: {
    // 获取单个课程信息
    getClassDetail(id) {
      let _this = this
      _this.API.ClassesDetail(id).then(res => {
        _this.disablesd = false
        Object.keys(_this.ruleForm).forEach(key=>{if(res[key] != null) _this.ruleForm[key]=res[key]})
        _this.ruleForm.id = res.id
        if(res.Tlistmodel != null) {
          _this.ruleForm.tlist = []
          res.Tlistmodel.map(t => {
            let obj = {
              type: t.type,
              teacherName: t.Tname,
              teacher_id: t.teacher_id
            }
            _this.ruleForm.tlist.push(obj)
            return t
          })
        }
        if(res.curriculum != null) this.$set(_this.ruleForm, 'curriculum_name', res.curriculum.curriculum_name)
        this.$forceUpdate()
        // this.ruleForm.curriculumid = res.curriculum.id
        // if(res.curriculum != null && res.curriculum.curriculum_name) {
        //   this.ruleForm.curriculum_name = res.curriculum.curriculum_name
        // }
        // this.ruleForm.tlist = res.Tlistmodel
        // this.$forceUpdate()
      })
    },
    editHandle() {
      this.editVisible = true;
    },
    // 获取教室列表
    getClassroomList() {
      let obj = {
        shoolid: 0,
        PageIndex: 1,
        PageSize: 100
      };
      this.API.getClassroomList(obj).then(res => {
        this.classroomList = res.data.rows;
        this.ruleForm.roomid = res.data.rows[0].id
      });
    },
    queryTeacherSearch(queryString, cb) {
      this.getTeacherList(queryString).then(res => {
        cb(res.data.rows || []);
      });
    },
    // 获取教师列表
    getTeacherList(keyword) {
      let obj = {
        // post_type: [2],
        post_tag:['教师'],
        KeyWord: keyword
      };
      return this.API.accountQuery(obj);
    },
    selectChange(index,item) {
      let arr =  this.ruleForm.tlist[index]
      if(arr.type == 1) {
        arr.teacher_id = item.id;
      }else {
        arr.teacher_id = item.id;
      }
    },
    // 获取校区列表
    getSchoolList() {
      let obj = {
        type: 1,
        PageIndex: 1,
        PageSize: 10
      };
      this.API.OrganizationList(obj).then(res => {
        if (res.success) {
          this.schoolList = res.data.rows;
        }
      });
    },
    // 选中校区
    schoolChange(e) { 
    },
    // 新建教室
    addClassroom() {
      let obj = this.classroomForm;
      this.API.addClassrooms(obj).then(res => {
        if (res.success) {
          this.dialogFormVisible = false;
          this.$message.success(res.message);
          this.getClassroomList();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    submitForm() {
      this.$refs['ruleForm'].validate(valid => {
        if (valid) {
          this.API.updateClass(this.ruleForm).then(res => { 
            if (res.code == 200) {
              this.$message.success(res.message);
              this.$router.go(-1)
              this.resetForm();
            } else {
              this.$message.error(res.messgae);
            }
          });
        } else { 
          return false;
        }
      });
    },
    resetForm() {
      this.$refs['ruleForm'].resetFields();
    },
    chooseCoursesSingle(){
      this.$Dialog.ChooseCoursesSingle().then(res => {
		  if(JSON.stringify(res)!='{}'){
			  this.ruleForm.curriculum_name = res.curriculum_name;
			  this.ruleForm.curriculumid = res.id;
		  }
        this.disablesd = false;
        this.$forceUpdate()
      });
    },
    addTeacher() {
      let obj = {
        teacher_id: '',
        type: 1,
      };
      this.ruleForm.tlist.push(obj);
    },
    delTeacher(index) {
      this.ruleForm.tlist.splice(index, 1)
    }
  }
};
</script>

<style scoped>
  /deep/ .as-autocomplete-list {
    width: 200px;
  }
</style>
